.router-route {
    opacity:0;
    transition:opacity 0.5s;
    width:100%;
}
.router-route.router-current{
    position:relative;
    opacity:1;
    z-index:2;
    animation: rise 0.5s;
}
@keyframes rise {
    from {opacity:0;}
    to {opacity:1;}
}
.router-route.router-current.router-samecomponent {
    animation: none;
}
.router-route.router-previous{
    position: absolute;
    z-index:1;
    opacity:0;
    height:100%;
    overflow:hidden;
}
.router {
    position:relative;
    top:0;
    left:0;
    width:100%;
}
.router-route>div:first-child {
    min-height:calc( 100vh - 3vw - 30px );
    padding-bottom:60px;
}
@media (max-width:767px) {
    .router-route>div:first-child {
        padding-top:5vw;
    }
}
@media (min-width:768px ) and (max-width:991px) {
    .router-route>div:first-child {
        padding-top:5vw;
    }
}
@media (min-width:992px ) and (max-width:1199px) {
    .router-route>div:first-child {
        padding-top:1vw;
    }
}
