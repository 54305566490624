.blog-table-container {
    position:relative;
    height:80px;
    width:100%;
    overflow:hidden;
}
.blog-table-container table {
    position: absolute;
    width:100%;
    left:0;
    height:0;
}
