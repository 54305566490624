@font-face {
  font-family: 'Syncopate-Fab';
  src: url('fonts/Syncopate-Fab.eot?#iefix') format('embedded-opentype'),  url('fonts/Syncopate-Fab.woff') format('woff'), url('fonts/Syncopate-Fab.ttf')  format('truetype'), url('fonts/Syncopate-Fab.svg#Syncopate-Fab') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans-Regular';
  src: url('fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),  url('fonts/OpenSans-Regular.woff') format('woff'), url('fonts/OpenSans-Regular.ttf')  format('truetype'), url('fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GillSans-Light';
  src: url('fonts/GillSans-Light.eot?#iefix') format('embedded-opentype'),  url('fonts/GillSans-Light.woff') format('woff'), url('fonts/GillSans-Light.ttf')  format('truetype'), url('fonts/GillSans-Light.svg#GillSans-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GillSans-LightItalic';
  src: url('fonts/GillSans-LightItalic.eot?#iefix') format('embedded-opentype'),  url('fonts/GillSans-LightItalic.woff') format('woff'), url('fonts/GillSans-LightItalic.ttf')  format('truetype'), url('fonts/GillSans-LightItalic.svg#GillSans-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GillSans';
  src: url('fonts/GillSans.eot?#iefix') format('embedded-opentype'),  url('fonts/GillSans.woff') format('woff'), url('fonts/GillSans.ttf')  format('truetype'), url('fonts/GillSans.svg#GillSans') format('svg');
  font-weight: normal;
  font-style: normal;
}
body {
    margin:0;
    padding:0;
    font-family:'OpenSans-Regular';
    font-size:16px;
    width:100%;
}
#root {
    overflow-x:hidden;
}
#canvas-container {
    position:fixed;
    z-index:0;
    top:0;
    left:0;
    width:100%;
    overflow:hidden;
    height:100%;
    transform-origin: top left;
}
canvas {
    position:absolute;
    width:2000px;
    height:2000px;
    top:-60px;
    left:0;
}
.clearfix {
    clear:both;
}
.pointer {
    cursor:pointer;
}
a, a:visited, a:active, a:hover, a:focus, span.lien, .focus-link  {
    text-decoration:none;
    color:#003e67;
    outline: 0;
}
a:active, a:hover, a:focus, .pointer:hover  {
    opacity:0.9;
}
p {
    text-align:justify;
}
h3 p {
    text-align:left;
}
.app {
    opacity:0;
    transition:opacity 0.5s;
}
.app.loaded {
    opacity:1;
}
.liste {
    margin-top:30px;
}
.intro {
    margin-bottom:30px;
}

.titre {
    font-family: 'Syncopate-Fab';
    font-weight: lighter;
}
.header {
    position:absolute;
    width:100%;
    top:0;
    left:0;
    height:21vw;
}
.wait, .wait-sync {
    opacity:0;
    transition: opacity 1s;
}
.ok {
    opacity:1;
    transition: opacity 1s;
}
.main {
    margin-top:23.5vw;
    position:relative;
}
.first {
	margin-top: 0.5vw;
}
.main h3 {
    font-size:40px;
    color:#999;
    margin-top:40px;
}
.main h3:first-child {
    margin-top:0px;
}

.main h3.logo {
    position:absolute;
    right:1vw;
    top: -8.5vw;
    text-align: right;
    font-size: 6vw;
    margin: 0;
    padding: 0;
    color:#000;
    color:#003e67;
    background-color: transparent;
    cursor:pointer;
}
.main div.logo.soustitre {
	font-family: 'GillSans-Light';
	position: absolute;
	right: -0.7vw;
	top: -3.8vw;
	text-align: right;
	margin: 0;
	padding: 0;
	color: #CCC;
	background-color: transparent;
	letter-spacing: 2.17vw;
	font-size: 2.4vw;
	font-style: normal;
	opacity:1;
	transition: right 1s, opacity 0.5s;

}
.main.entered div.logo.soustitre {
	opacity:0;
	right:-100vw;
}
.main.entered h3.logo {
	position: absolute;
	right: 0;
	top: 0;
	text-align: right;
	font-size: 1.5vw;
	margin: 0;
	/* padding: 0; */
	color: #FFF;
	height: 3vw;
	padding: 0.75vw;
	background-color: #003e67;
}
.main .boutons {
	position: absolute;
	top:0;
	left: 0;
	text-align: center;
	line-height: 0;
	width: 96%;
	left: 2%;
    transition: all 0.5s;
}
.gutter {
	width: 5vw;
}
.main.entered .boutons {
	top: 0;
	width: 80%;
}
.main .menu td {
	font-family: 'Syncopate-Fab';
	font-weight: lighter;
	font-size: 1vw;
	/* padding-left: 2vw; */
	color: #000;
	line-height: 1vw;
	transition: font-size 0.5s;
}
.main .menu {
    height:3vw;
    background-color:#FFF0;
    transition: background-color 2s;
    box-shadow: 0 0 20px #0000;
    z-index:100;
}
.main.entered .menu {
    background-color:#FFFE;
    box-shadow: 0 0 20px #0002;
    transition: background-color 1s, box-shadow 2s;
}
#spip-admin {
    display:none;
}
.boutons {
    cursor:pointer;
}
.img-responsive {
    width:100%;
}
#main-spacer {
	height:calc(3vw + 30px);
}
.tuile, .shadow {
	border-radius: 4px;
	background: #FFFA;
	overflow: hidden;
	box-shadow: 0px 0px 20px #0002;
}
.tuile {
	margin-bottom:30px;
}

.tuile td {
    text-align:center;
    font-family: 'Syncopate-Fab';
    margin:15px;
    font-size:1.5em;
    color:#000;
}
.tuile.groupe td, h3.groupe  {
    text-align:center;
    font-family: 'GillSans-Light';
    margin:15px 0;
    font-size:30px;
    line-height:30px;
}
.tuile table {
    width:100%;
}
.tuile td {
    height:80px;
    padding:5px;
}
.slide h3.titre {
    margin-top:0;
}
.inter {
    height:30px;
}
.caption {
	position: absolute;
	bottom: 10px;
	left: 30px;
	right:30px;
	color: #FFF;
	text-shadow: 0 0 10px #0008;
}
.groupe {
    font-family: 'GillSans-Light';
}
.caption h3 {
	margin: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	color: #FFF;
	width: 70%;
	text-align: left;
}
.caption .tag {
    position:absolute;
    bottom:0;
    right:0;
    width: 30%;
    text-align:right;
}


.inter {
    height:30px;
}
.back {

}
.retour-ctrl {
    display:inline-block;
	border-radius: 4px;
	background-color:#003e67ff;
	box-shadow: 0px 0px 20px #0002;
	color:#FFF;
    padding: 8px;
    line-height:24px;
    margin-right: 8px;
    margin-top: 4px;
    user-select:none;
    text-align:left;
    width:max-content;

}
.button {
	border-radius: 4px;
	background-color:#003e6788;
	box-shadow: 0px 0px 20px #0002;
	color:#FFF;
    padding: 2px 8px;
    margin-right: 8px;
    user-select:none;
    cursor:pointer;
}
.button:hover, .button.on {
	background-color:#003e67ff;
}
.retour-btn, .nav-btn {
    margin-bottom:20px;
}
.plus-btn {
    margin-top:60px;
}
.next {
    opacity:0.1;
}
.next.pointer {
    opacity:1;
}
.prev {
    opacity:0.1;
}
.prev.pointer {
    opacity:1;
}
.prev.pointer:hover, .next.pointer:hover  {
    opacity:0.9;
}
.detail h3 {
    font-family: 'Syncopate-Fab';
}
.hidden {
    display:none !important;
}
.detail-date {
	margin: 0;
}
.spip_documents_center, .spip_documents_center img {
    max-width:100%;
}

h3.spip {
    font-family: 'Syncopate-Fab';
    font-size:24px;
}
.focus-link {
	position: absolute;
	bottom: -22px;
	background: #FFF;
	padding: 5px 10px;
	border-radius: 4px;
	box-shadow: 0px 0px 20px #0002;
	right: 30px;
    cursor:pointer;
}
.focus-item > div {
	padding: 15px;
	background-color: #003e67CC;
	color: #FFF;
	border-radius: 4px;
	box-shadow: 0px 0px 20px #0002;
}
.focus .clearfix {
    height:30px;
}

#main-container {
    min-height:calc(100vh - 3vw - 30px);
}

.boutons td {
    height:3vw;
}
.boutons td span{
    display:inline-block;
    height:3vw;
    padding:1vw 0.7vw;
    user-select: none;
    transition: background-color 1s;
}
.boutons td.active span {
    background-color:#003e6788;
    color:#FFF;
    border-radius:4px;
    transition: border-radius 0.5s;
}
.entered .boutons td.active span {
    border-radius:0px;
}
.disabled {
    opacity: 0.2;
}
.fadein {
    animation: fadein 1s;
}
@keyframes fadein {
    from {opacity:0;}
    to {opacity:1;}
}
.main-audio-player {
    position:fixed;
    bottom:0;
    padding:0 15px;
    width:100%;
    z-index:10;
    padding-top:15px;
    opacity:0.5;
    background-color: #FFF0;
    transform: translateY(100px);
    transition: opacity 0.5s, background-color 0.5s, transform 1s;
}
.main.entered .main-audio-player {
    opacity:0.5;
    background-color: #FFF0;
    transform: translateY(0);
    transition: opacity 0.5s, background-color 0.5s, transform 0.5s;
}
.main.entered .main-audio-player:hover {
    transform: translateY(0);
    opacity:1;
    background-color: #FFFE;
}
.pad0 {
    padding:0;
}
.img-bandeau {
    position:relative;
}
.img-bandeau-titre {
    position:absolute;
    bottom:30px;
    color:#FFF;
    font-size:50px;
}
.lg .paragraphe2 div {
    columns: 2 auto;
    column-gap: 60px;
}
.lg .paragraphe2 div p {
    break-inside: avoid;
}
.xs .swiper-button-prev, .xs .swiper-button-next {
    display:none;
}
.burger {
    display:none;
}
span.button {
    line-height:25px;
    margin-bottom:5px;
    display:inline-block;
}
@media (max-width:767px) {
    .header {
        height:calc(100vh - 90px);
    }
    .main {
        margin-top:calc(100vh - 90px);
    }
    .step {
        padding-top:50px;
    }
    .step .titre {
        position:inherit;
        top:0;
    }
    .main h3 {
        font-size:30px;
    }
    .main h3.logo {
        font-size: 12vw;
        text-align:center;
        top:-30vw;
        width:100%;
        right:0;
    }
    .main div.logo.soustitre {
        font-size: 3.7vw;
        width:100%;
        text-align:center;
        top:-5vw
    }
    .main.entered h3.logo.titre  {
        position:fixed;
        height:10vw;
        font-size:6vw;
        line-height:10vw;
        padding-right:2vw;
        z-index:101;
    }
    .main.entered .menu {
        height:8vw;
    }
    .main .menu {
        height:8vw;
    }
    .main .menu .boutons{
        display:none;
    }
    .burger {
        display:none;
        z-index:101;
        position:fixed;
        width:9vw;
        left:1.5vw;
        top:0.5vw;
        cursor:pointer;
    }
    .entered .burger {
        display:block;
    }
    .burger svg {
        fill:#FFF;
    }
    .swiper-container {
        margin-top:60px;
    }
    .focus-item {
        margin-bottom:30px;
    }
    .plan ul {
        width:100%;
    }
    .main.entered .main-audio-player {
        opacity:0.9;
        background-color: #FFF;
    }
}
@media (min-width:768px ) and (max-width:991px) {
    .header {
        height:calc(55vw - 90px);
    }
    .main {
        margin-top:calc(55vw - 90px);
    }
    .main h3.logo {
        font-size: 8.2vw;
        right:0.9vw;
    }
    .main.entered h3.logo {
        width:inherit;
    }
    .main div.logo.soustitre {
        font-size: 3.7vw;
        width:100%;
        text-align:center;
        top:-1vw
    }
    .main.entered h3.logo.titre  {
        position:fixed;
        z-index:100;
        top:0;
        width:100%;
        height:7vw;
        font-size:5vw;
        line-height:7vw;
        padding-right:1.2vw;
    }
    .main .menu {
        height:5vw;
        transition:none;
    }
    .main.entered .menu {
        background-color: #003e67;
        transition:none;
    }
    .main .menu .boutons{
        display:none;
    }
    .burger {
        display:none;
        position:fixed;
        z-index:101;
        width:6vw;
        left:1vw;
        top:0.5vw;
        cursor:pointer;
        display:none;
    }
    .main.entered .burger {
        display:block;
    }
    .burger svg {
        fill:#FFF;
    }
    .first {
        margin-top:8vw;
    }
    .slides-container {
	    top: 5vw;
    }
    .step .titre {
        top: 8vw;
    }
    .focus-item {
        margin-bottom:30px;
    }
    .main.entered .main-audio-player {
        opacity:0.9;
        background-color: #FFF;
    }
}
@media (min-width:992px) and (max-width:1199px) {
    .header {
        height:calc(55vw - 90px);
    }
    .main {
        margin-top:calc(55vw);
    }
    .main .menu {
        margin-bottom:7vw;
    }
    .main.entered {
        padding-top:3vw;
    }
    .main div.logo.soustitre {
        font-size: 3.7vw;
        width:100%;
        text-align:center;
        top:-1vw
    }
    .main h3.logo {
        font-size: 8.2vw;
        right:0.9vw;
    }
    .main.entered h3.logo {
        width:inherit;
    }
    .focus-item {
        margin-bottom:30px;
    }
    .main.entered .menu td {
        font-size: 1vw;
    }
    .main.entered .menu {
        position:fixed;
        top:0;
        width:100%;
    }
    .main.entered .menu .titre {
        position:fixed;
        width:max-content;
    }
    .main .boutons {
        top:6vw;
    }
    .main.entered .boutons {
        position:fixed;
        top:0;
        width:76%;
    }
    .gutter {
	    width: 0;
    }
    .main .menu td {
    	font-size:1.2vw;
    }
    .boutons td span {
    	padding: 1vw 0.5vw;
    }
    .first {
        margin-top:11vw;
    }
    .slides-container {
	    top: 5vw;
    }
    .step .titre {
        top: 8vw;
    }
}
@media (min-width:1200px) and (max-width:1599px) {
    .main {
        margin-top:40vw;
    }
    .main.entered {
        padding-top:3vw;
    }
    .main h3.logo {
        top:-14vw;
        font-size: 6vw;
    }
    .header {
        height:40vw;
    }
    .main div.logo.soustitre {
        top:-8vw
    }
    .main.entered .menu td {
        font-size: 1vw;
    }
    .main.entered .menu {
        position:fixed;
        top:0;
        width:100%;
    }
    .main .boutons {
        top:-2vw;
    }
    .main.entered .boutons {
        position:fixed;
        top:0;
        width:76%;
    }
    .gutter {
	    width: 1vw;
    }
    .slides-container {
	    top: 5vw;
    }
    .step .titre {
        top: 8vw;
        font-size:14px;
    }

}
@media (min-width:1600px) {
    .main {
        margin-top:27vw;
    }
    .main.entered {
        padding-top:3vw;
    }
    .main h3.logo {
        font-size: 6vw;
    }
    .header {
        height:21vw;
    }
    .main.entered .menu {
        position:fixed;
        top:0;
        width:100%;
    }
    .main.entered .menu td {
        font-size: 1vw;
    }
    .main.entered .boutons {
        position:fixed;
        top:0;
        width:76%;
    }
    .gutter {
	    width: 1vw;
    }
    .slides-container {
	    top: 5vw;
    }
    .step .titre {
        top: 8vw;
    }

}
