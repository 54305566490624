svg.fond g path {
display:inline;
fill:none;
fill-rule:evenodd;
stroke:#000000;
stroke-width:1.79999995;
stroke-linecap:butt;
stroke-linejoin:miter;
stroke-miterlimit:4;
stroke-dasharray:none;
stroke-opacity:1;
}
svg.fond {
    width:100%;
    transform-origin: top left;
}
.canvas {
    position:absolute;
    width:2000px;
    height:2000px;
    top:-60px;
    left:0;
    transform-origin: top left;
}
//xs
.xs svg.fond{
    transform: translate(-2500px,100px) scale(4);
    transition: all 1s;
}
.xs svg.fond path{
    stroke:#003e67CC;
    stroke-width:2;
    transition: all 1s;
}
.scrolled .xs svg.fond{
    transform: translate(-5000px,0px) scale(7);
    transition: all 1s;
}
.scrolled .xs svg.fond path{
    stroke:#0001;
    stroke-width:5;
    transition: all 1s;
}
//sm
.sm svg.fond{
    transform: translate(-1500px,-800px) scale(3);
    transition: all 1s;
}
.sm svg.fond path{
    stroke:#003e67CC;
    stroke-width:2;
    transition: all 1s;
}
.scrolled .sm svg.fond{
    transform: translate(-3500px,-700px) scale(5);
    transition: all 1s;
}
.scrolled .sm svg.fond path{
    stroke:#0001;
    stroke-width:3;
    transition: all 1s;
}
//md
.md svg.fond{
    transform: translate(-500px,-350px) scale(2);
    transition: all 1s;
}
.md svg.fond path{
    stroke:#003e67CC;
    stroke-width:2;
    transition: all 1s;
}
.scrolled .md svg.fond{
    transform: translate(-2000px,-500px) scale(4);
    transition: all 1s;
}
.scrolled .md svg.fond path{
    stroke:#0001;
    stroke-width:3;
    transition: all 1s;
}
//lg
.lg svg.fond{
    transform: translate(-200px,-300px) scale(1.5);
    transition: all 1s;
}
.lg svg.fond path{
    stroke:#003e67CC;
    stroke-width:2;
    transition: all 1s;
}
.scrolled .lg svg.fond{
    transform: translate(-2000px,-1000px) scale(4);
    transition: all 1s;
}
.scrolled .lg svg.fond path{
    stroke:#0001;
    stroke-width:3;
    transition: all 1s;
}
