.son h4 {
    font-family: 'GillSans-Light';
    font-size:30px;
}
.xs .son h4 {
    font-family: 'GillSans-Light';
    font-size:20px;
}
.son.xSmall {
    display:inline-block;
    margin-left:10px;
}
.son .son-titre {
    text-transform:uppercase;
}
.son .son-groupe {
    margin-left:10px;
    font-size:16px;
}
.xs .son .son-groupe {
    margin-left:0;
}
.son.small h4, .son.xSmall h4 {
    display:none;
}
.son-titre-small {
    font-family: 'GillSans-Light';
    font-size:18px;
    margin-left:50px;
    line-height: 18px;
    text-transform:uppercase;
    display:none;
}
.son-titre-small div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow:hidden;
    max-width:60vw;
}
.son-groupe-small {
    font-family: 'GillSans-Light';
    font-size:16px;
    margin-left:10px;
    line-height: 18px;
    opacity:0.8;
    display:none;
}
.son.small .son-titre-small {
    display:inline-block;
}
.son.small .son-groupe-small {
    display:inline-block;
}
.son-controls {
    position:relative;
    height:50px;
}
.son-progress {
    position:absolute;
    width:100%;
    top:20px;
}
.son .play {
    position:absolute;
    opacity:1;
    top:0;
    left:0;
    cursor:pointer;
    transition: opacity 0.2s linear 0.2s, left 0s linear 0.2s;
    width:18px;
    height:18px;
    background-image: url(../assets/Play.svg);
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
}
.son.playing .play {
    opacity:0.2;
    left:-10000px;
    transition: opacity 0.2s, left 0s linear 0.2s;
}
.son .next {
    position:absolute;
    opacity:0.2;
    top:0;
    left:-10000px;
    cursor:pointer;
    transition:opacity 0.2s linear 0.2s, left 0s linear 0.4s;
    width:18px;
    height:18px;
    background-image: url(../assets/Next.svg);
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
}
.son.playing .next {
    opacity:1;
    left:23px;
    transition: opacity 0.2s, left 0s;
}
.son .pause {
    position:absolute;
    top:0;
    left:-10000px;
    opacity:0.2;
    cursor:pointer;
    transition:opacity 0.2s linear 0.2s, left 0s linear 0.4s;
    width:18px;
    height:18px;
    background-image: url(../assets/Pause.svg);
    background-size:contain;
    background-repeat: no-repeat;
    background-position: center;
}
.son.playing .pause {
    opacity:1;
    left:0px;
    transition: opacity 0.2s, left 0s;
}
.son.xSmall .play, .son.xSmall .pause, .son.xSmall .next {
    width:1em;
    height:1em;
}
.son.xSmall.playing .next {
    left:1.2em;
}
.son.xSmall .son-controls {
    height:1em;
}
